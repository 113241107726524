import request from '@/utils/request'

// 获取系统配置
export function getConfig(data) {
  return request({
    url: '/api/services/app/Configuration/GetSystemSettings',
    method: 'get',
    params: data
  })
}

export function saveConfig(data) {
  return request({
    url: '/api/services/app/Configuration/ChangeSystemSettings',
    method: 'post',
    data
  })
}

// 获取分成配置
export function getDistributionSettings(data) {
  return request({
    url: '/api/services/app/Configuration/GetDistributionSettings',
    method: 'get',
    params: data
  })
}

export function ChangeDistributionSettings(data) {
  return request({
    url: '/api/services/app/Configuration/ChangeDistributionSettings',
    method: 'post',
    data
  })
}

// 获取代理配置
export function getAgentSettings(data) {
  return request({
    url: '/api/services/app/Configuration/GetAgentSettings',
    method: 'get',
    params: data
  })
}

export function ChangeAgentSettings(data) {
  return request({
    url: '/api/services/app/Configuration/ChangeAgentSettings',
    method: 'post',
    data
  })
}

// 获取客户端配置
export function GetClientConfig(data) {
  return request({
    url: '/api/services/app/Configuration/GetClientConfig',
    method: 'get',
    params: data
  })
}

export function ChangeClientConfig(data) {
  return request({
    url: '/api/services/app/Configuration/ChangeClientConfig',
    method: 'post',
    data
  })
}

// 发送app升级通知
export function PublishMaintainNotification(data) {
  return request({
    url: '/api/services/app/Notification/PublishMaintainNotification',
    method: 'post',
    data
  })
}
