<template>
    <el-dialog custom-class="comp-dialog-map" title="地图选点" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
      <div>
        <el-row>
          <el-col :span="24">
            <el-amap-search-box
              class="search-box"
              :search-option="searchOption"
              :on-search-result="onSearchResult"
            ></el-amap-search-box>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="width: 100%; height: 600px;">
            <el-amap
              vid="amapDemo"
              :center="center"
              :zoom="zoom"
              class="amap-demo"
              :events="events"
            >
              <el-amap-marker
                v-for="(marker, index) in markers"
                :position="marker"
                :key="index"
              ></el-amap-marker>
            </el-amap>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="toolbar">
              <el-button type="primary" @click="mapBack">确定</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </template>
  
  <script>
  import VueAMap from "vue-amap";
  let amapManager = new VueAMap.AMapManager();
  import location from "./positionLocation";
  export default {
    name: "compMap",
    data() {
      let self = this;
      return {
        showDialog: false,
        location: "",
        amapManager,
        zoom: 13,
        markers: [[104.065683, 30.657497]],
        center: [104.065683, 30.657497],
        address: "", // 地址
        lng: 0, // 维度
        lat: 0, // 经度
        searchOption: {
          city: "",
          citylimit: false,
        },
        events: {
          click(e) {
            let { lng, lat } = e.lnglat;
            self.markers = [];
            self.lng = lng;
            self.lat = lat;
            self.center = [lng, lat];
            self.markers.push(self.center);
            // 这里通过高德 SDK 完成。
            var geocoder = new AMap.Geocoder({
              radius: 1000,
              extensions: "all",
            });
            geocoder.getAddress(self.center, function (status, result) {
              if (status === "complete" && result.info === "OK") {
                if (result && result.regeocode) {
                  // self.address = result.regeocode.formattedAddress;
                  self.address = result.regeocode;
                  self.$nextTick();
                }
              }
            });
          },
        },
      };
    },
  
  
    mounted() {
      
    },
    methods: {
      open(item) {
        if(item.x && item.y) {
          this.lng = item.x;
          this.lat = item.y;
          this.address = item.address;
          this.markers = [];
          this.markers.push([item.x, item.y]);
          this.center = [item.x, item.y];
        }else{
          this.getLocation();
        }
        this.showDialog = true;
      },
      // 隐藏
      mapBack() {
        this.$emit("getLnglat", this.lng, this.lat, this.address);
        this._close();
        // this.$emit("getYin", this.center);
      },
      // 搜索
      onSearchResult(pois) {
        let latSum = 0;
        let lngSum = 0;
        if (pois.length > 0) {
          pois.forEach((poi) => {
            let { lng, lat } = poi;
            lngSum += lng;
            latSum += lat;
          });
          let center = {
            lng: lngSum / pois.length,
            lat: latSum / pois.length,
          };
          this.center = [center.lng, center.lat];
          this.zoom = 15;
        }
      },
      // 定位
      getLocation() {
        let _that = this;
        let geolocation = location.initMap("map-container"); // 定位
        AMap.event.addListener(geolocation, "complete", (result) => {
          console.log(result)
          let { lng, lat } = result.position;
          _that.lat = result.position.lat;
          _that.lng = result.position.lng;
          _that.center = [result.position.lng, result.position.lat];
          _that.location = result.formattedAddress;
          _that.markers.push([result.position.lng, result.position.lat]);
        });
      },
      _close() {
        this.showDialog = false;
      }
    },
  };
  </script>
  
  <style lang="less">
  .comp-dialog-map {
    margin-top: 6vh !important;
    width: 92%;
    min-width: 600px;
    height: auto;
    .el-dialog__body {
      padding-top: 10px;
    }
  }
  .amap-demo {
    height: 100%;
    width: 100%;
  }
  .toolbar {
    display: flex;
    justify-content: flex-end;
  }
  .search-box {
    border: 1px solid #eee;
  }
  </style>