<style lang="scss" scoped>
.page-merchant-details {
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .scv /deep/ .el-form-item__content {
        display: flex;
        align-items: flex-end;
    }

    /deep/ .avatar-uploader {
        .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        .el-upload:hover {
            border-color: #409eff;
        }

        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px !important;
        text-align: center;
    }
}
</style>

<template>
    <div class="page-merchant-details">
        <template v-if="merchantData">
            <el-card class="box-card">
                <div slot="header" class="card-header"><span>基础信息</span></div>
                <el-form :model="merchantData" :rules="rules" ref="refMerchantData" label-width="130px"
                    class="demo-ruleForm">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="行业" prop="categoryId">
                                <el-select v-model="merchantData.categoryId" placeholder="请选择行业" filterable>
                                    <el-option label="请选择行业" :value="merchantData.categoryName"></el-option>
                                    <el-option v-for="item in merchantIndustry" :label="item.name" :value="item.id"
                                        :key="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="所属代理">
                                <el-select v-model="merchantData.agentId" filterable remote clearable reserve-keyword
                                    placeholder="搜索代理" :remote-method="renderAgentOptions" @clear="renderAgentOptions()">
                                    <el-option v-for="item in agentItems" :key="item.id" :label="`${item.name}【${item.id}】`"
                                        :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="商户名称" prop="name">
                                <el-input v-model="merchantData.name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="联系人" prop="contractName">
                                <el-input v-model="merchantData.contractName"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="负责人联系电话" prop="internalContractTel">
                                <el-input v-model="merchantData.internalContractTel"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="前台电话" prop="contractTel">
                                <el-input v-model="merchantData.contractTel"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-if="merchantData.address">
                        <el-col :span="24">
                            <el-form-item class="fl" label="选择省" prop="address.provinceCode">
                                <el-select @click.native="clearAddress" v-model="merchantData.address.provinceCode"
                                    placeholder="请选择省">
                                    <el-option v-for="item in provinceOptions" :key="item.id" :label="item.name"
                                        :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="fl" label="选择市" prop="address.cityCode">
                                <el-select @click.native="clearAddress" v-model="merchantData.address.cityCode"
                                    placeholder="请选择市">
                                    <el-option v-for="item in cityOptions" :key="item.id" :label="item.name"
                                        :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="fl" label="选择区/县" prop="address.areaCode">
                                <el-select @click.native="clearAddress" v-model="merchantData.address.areaCode"
                                    placeholder="请选择区/县">
                                    <el-option v-for="item in countyOptions" :key="item.id" :label="item.name"
                                        :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="商户地址" prop="address.street">
                                <el-input v-model="merchantData.address.street"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="经度" prop="lng">
                                <el-input v-model="merchantData.lng" @click.native="mapShow" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="纬度" prop="lat">
                                <el-input v-model="merchantData.lat" @click.native="mapShow" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-button style="margin-left: 10px;" type="primary" @click="mapShow">地图选点</el-button>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="商户介绍">
                                <el-input type="textarea" :rows="5" v-model="merchantData.desc"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="启用状态">
                                <el-switch v-model="merchantData.isActive" @change="changeState"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col :span="18">
                            <el-form-item label="收费价格">
                                <span style="margin-right: 10px">
                                    <template v-if="feeInfo && feeInfo.rentSettings && feeInfo.rentSettings.rentPrice">
                                        {{ feeInfo.rentSettings.freeMinutes }}分钟内免费,
                                        <span v-if="feeInfo.rentSettings.startRent > 0">起价{{ feeInfo.rentSettings.startRent }}元/{{ feeInfo.rentSettings.startRentUnitDesc }},</span>
                                        超出后{{ feeInfo.rentSettings.rentPrice }}元/{{ feeInfo.rentSettings.rentTimeUnitDesc }},
                                        日封顶{{ feeInfo.rentSettings.maxDayRent }}元
                                    </template>
                                    <template v-else>未设置</template>
                                </span>
                                <el-button type="primary" icon="el-icon-edit" circle size="mini"
                                    @click="$refs.merchantFeeSettingDialog.show(id, feeInfo)"></el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="订单总数">
                                <span>{{ merchantData.orderCount }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="订单总额">
                                <span>{{ merchantData.orderTurnover }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="设备数量">
                                <span>{{ merchantData.deviceCount }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="在线设备数量">
                                <span>{{ merchantData.onlineDeviceCount }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="离线设备数量">
                                <span>{{ merchantData.offlineDeviceCount }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="创建时间">
                                <span>{{ merchantData.creationTime }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="商家图片" prop="imageUrl" class="scv">
                                <el-upload class="avatar-uploader" name="File" :action="uploadUrl" :show-file-list="false"
                                    :on-success="handleBannerImageSuccess" :before-upload="beforeUpload">
                                    <img v-if="merchantData.imageUrl" :src="merchantData.imageUrl" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <el-button type="primary" size="mini"
                                    @click="imgFuc(merchantData.imageUrl)">查看图片</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item>
                        <el-col style="display:flex;justify-content: flex-end">
                            <el-button type="primary" @click="onSubmit('merchantData')">保存修改</el-button>
                        </el-col>
                    </el-form-item>
                </el-form>
            </el-card>
        </template>
        <merchant-fee-setting-dialog ref="merchantFeeSettingDialog" @confirm="renderFeeInfo"></merchant-fee-setting-dialog>
        <!--地图-->
        <chooseMap :ref="refMap" @getLnglat="getLnglat"></chooseMap>
    </div>
</template>

<script>
import * as appRegion from "@/utils/app_region"
import * as funCommon from "@/api/all"
import * as funAgent from "@/api/agent/index"
import * as funMerchant from "@/api/merchant/index"
import MerchantFeeSettingDialog from "./components/MerchantFeeSettingDialog";
import chooseMap from "./map.vue"
export default {
    name: "pageMerchantDdetails",
    components: { MerchantFeeSettingDialog, chooseMap },
    data() {
        return {
            refMap: "refMerchantDetailToMap",
            uploadUrl: "",
            id: "",
            isClearAddress: false,
            isShowMap: false, // 显示地图
            merchantData: {
                id: 0,
                agentId: null,
                categoryId: null,
                name: "",
                imageUrl: "",
                contractName: "",
                contractTel: "",
                internalContractTel: "",
                desc: "",
                address: {
                    provinceCode: null,
                    cityCode: null,
                    areaCode: null,
                    street: ""
                },
                lat: null,
                lng: null,
                managerId: 0
            },
            agentItems: [],
            merchantAddress: "",
            merchantIndustry: [],
            provinceOptions: [],
            cityOptions: [],
            countyOptions: [],
            feeInfo: null,
            rules: {
                name: [{ required: true, message: "请输入商户名称", trigger: "blur" }],
                categoryId: [{ required: true, message: "请选择行业", trigger: ["blur", "change"] }],
                internalContractTel: [
                    { required: true, message: "请填写手机号", trigger: "blur" },
                    { pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/, message: "手机号不正确" }
                ],
                contractName: [{ required: true, message: "请输入联系人", trigger: "blur" }],
                contractTel: [
                    { required: true, message: "请填写电话", trigger: "blur" },
                    { pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/, message: "电话不正确" }
                ],
                "address.provinceCode": [{ required: true, message: "请选择省", trigger: ["blur", "change"] }],
                "address.cityCode": [{ required: true, message: "请选择市", trigger: ["blur", "change"] }],
                "address.areaCode": [{ required: true, message: "请选择区/县", trigger: ["blur", "change"] }],
                "address.street": [{ required: true, message: "请填写地址", trigger: ["blur", "change"] }],
                lng: [{ required: true, message: "请设置经度", trigger: ["blur", "change"] }],
                lat: [{ required: true, message: "请设置纬度", trigger: ["blur", "change"] }],
                imageUrl: [{ required: true, message: "请上传图片", trigger: ["blur", "change"] }]
            }
        }
    },
    watch: {
        "merchantData.address.provinceCode"(provinceCode) {
            if (this.isClearAddress) {
                this.merchantData.address.cityCode = ""
                this.merchantData.address.areaCode = ""
            }
            this.cityOptions = appRegion.getCitys(provinceCode)
            this.cityOptions = this.cityOptions && this.cityOptions.map(x => {
                x.id = Number(x.id)
                return x
            })
            this.countyOptions = []
        },
        "merchantData.address.cityCode"(cityCode) {
            if (this.isClearAddress) {
                this.merchantData.address.areaCode = ""
            }
            this.countyOptions = appRegion.getCountys(cityCode)
            this.countyOptions = this.countyOptions && this.countyOptions.map(x => {
                x.id = Number(x.id)
                return x
            })
        }
    },
    created() {
        this.uploadUrl = this.uploadBaseUrl + "/api/services/app/Shared/UploadFile"
        this.isClearAddress = false
        this.initPage()
    },
    activated() {
        if (this.id != this.$route.query.id) {
            this.initPage()
        }
    },
    methods: {
        clearAddress() {
            this.isClearAddress = true
        },
        // 点击详细地址
        mapShow() {
            this.$refs[this.refMap].open({
                x: this.merchantData.lng,
                y: this.merchantData.lat,
                address: this.merchantData.address.street
            })
        },
        // 选择地址之后
        getLnglat(x, y, e) {
            if (e && e.addressComponent) {
                this.getAddressByMap(e.addressComponent)
            }
            this.merchantData.lng = x || this.merchantData.lng || null
            this.merchantData.lat = y || this.merchantData.lat || null
        },
        getAddressByMap(item) {
            let countyCode = `${item.adcode}000000`
            let cityCode = `${countyCode}`.substr(0, 4) + '00000000'
            let provinceCode = `${countyCode}`.substr(0, 2) + '0000000000'
            this.merchantData.address.provinceCode = Number(provinceCode) || ""
            this.merchantData.address.cityCode = Number(cityCode) || ""
            this.merchantData.address.areaCode = Number(countyCode) || ""
            this.merchantData.address.street = `${item.street}${item.streetNumber}${item.neighborhood}`
        },
        async renderMerchantInfo() {
            window.$common.fullLoading()
            await funMerchant.GetMerchantDetail({ id: this.id }).then(async (res) => {
                window.$common.closeFullLoading()
                this.merchantData = res
                this.merchantAddress = `${appRegion.getProvinceByCode(res.address.provinceCode).name}
                ${appRegion.getCityByCode(res.address.cityCode).name}
                ${appRegion.getCountyByCode(res.address.areaCode).name} `
            })
        },
        async renderAgentOptions(query) {
            await funAgent.GetAgentAll({ keywords: query }).then(res => {
                this.agentItems = res
            })
        },
        // 获取费用设置
        async renderFeeInfo() {
            window.$common.fullLoading()
            await funMerchant.GetFreeSetInfo({ id: this.id }).then(res => {
                window.$common.closeFullLoading()
                this.feeInfo = res
            })
        },
        // 获取行业类别
        async readerTrade() {
            await funCommon.CategoryAll({ type: 2 }).then(res => {
                this.merchantIndustry = res
            })
        },
        // 改变启用/禁用状态
        changeState(val) {
            if (!this.merchantData.id) return false
            funMerchant.OpenOrClose({
                id: this.merchantData.id,
                isActive: val
            }).then(() => {
                this.successMsg("修改成功")
            })
        },
        // 修改图片
        handleBannerImageSuccess(res, file) {
            if (res.success) {
                this.merchantData.imageUrl = res.result.fileUrl
            }
        },
        beforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!")
            }
            return isLt2M
        },
        // 查看图片、
        imgFuc(url) {
            window.open(url)
        },
        async initPage() {
            this.id = this.$route.query.id
            this.provinceOptions = appRegion.getProvinces()
            this.provinceOptions = this.provinceOptions.map(x => {
                x.id = Number(x.id)
                return x
            })
            await this.readerTrade()
            await this.renderAgentOptions()
            if (this.id) {
                await this.renderMerchantInfo()
                await this.renderFeeInfo()
            }
        },
        // 保存修改
        onSubmit() {
            this.$refs["refMerchantData"].validate(valid => {
                if (valid) {
                    window.$common.fullLoading()
                    funMerchant.CreateOrUpdate(this.merchantData).then(res => {
                        window.$common.closeFullLoading()
                        this.successMsg("修改成功")
                    })
                }
            })
        }
    }
}
</script>