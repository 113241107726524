<style lang="scss" scoped>
/deep/ .comp-merchant-fee-setting {
    max-width: 650px;
}
</style>

<template>
    <div>
        <el-dialog title="设置套餐费用" custom-class="c-el-dialog comp-merchant-fee-setting" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="120px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="免费时长">
                            <el-select clearable filterable allow-create default-first-option
                                placeholder="可直接输入时长(分钟)" v-model.number="dialogData.rentSettings.freeMinutes">
                                <el-option
                                    v-for="item in chargingBatteryFreeTimeRange"
                                    :key="item.name"
                                    :label="item.name"
                                    :value="Number(item.value)"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="起步价">
                            <el-select clearable filterable allow-create default-first-option placeholder="可直接输入起步价(元)"
                                v-model.number="dialogData.rentSettings.startRent">
                                <el-option v-for="item in chargingBatteryPriceOptions" :key="item.name" :label="item.name" :value="Number(item.value)"></el-option>
                            </el-select>
                            <span class="mrgl5 mrgr5">/</span>
                            <el-select clearable filterable allow-create default-first-option placeholder="请选择起步单位时长" v-model.number="dialogData.rentSettings.startRentUnit">
                                <el-option v-for="item in chargingBatteryRentTimeOptions" :key="item.name" :label="item.name" :value="Number(item.value)"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="单价">
                            <el-select clearable filterable allow-create default-first-option placeholder="可直接输入单价(元)"
                                v-model.number="dialogData.rentSettings.rentPrice">
                                <el-option v-for="item in chargingBatteryPriceOptions" :key="item.name" :label="item.name" :value="Number(item.value)"></el-option>
                            </el-select>
                            <span class="mrgl5 mrgr5">/</span>
                            <el-select clearable filterable allow-create default-first-option placeholder="请选择单位时长" v-model.number="dialogData.rentSettings.rentTimeUnit">
                                <el-option v-for="item in chargingBatteryRentTimeOptions" :key="item.name" :label="item.name" :value="Number(item.value)"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="16">
                        <el-form-item label="24小时封顶金额">
                            <el-input type="number" :step="1" v-model.number="dialogData.rentSettings.maxDayRent" placeholder="请输入24小时封顶金额" clearable>
                                <template slot="append">元</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-tooltip class="item" effect="dark" content="套餐重置后，系统将以平台默认的收费价格为准。" placement="top-start">
                    <el-button size="small" type="danger" @click="_reset()">重置</el-button>
                </el-tooltip>
                <el-button size="small" @click="_close()">关闭</el-button>
                <el-button size="small" type="primary" @click="_save()">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funConfig from "@/api/system/sysConfig"
import * as funMerchant from "@/api/merchant/index"
export default {
    name: "compMerchantFeeSetting",
    mixins: [enumConfigs],
    data() {
        return {
            showDialog: false,
            formRefName: "refMerchantFeeSettingForm",
            dialogDataDefault: {
                rentSettings: {
                    rentPrice: null,
                    freeMinutes: null,
                    rentTimeUnit: null,
                    maxDayRent: null,
                    startRent: null,
                    startRentUnit: null
                },
                id: 0
            },
            dialogData: {},
            config: {},
            formRules: {}
        }
    },
    computed: {
        chargingBatteryRentTimeOptions() {
            return this.arrDeviceRentTimeUnit
        },
        chargingBatteryPriceOptions() {
            return this.config.chargeTreasureCharge
        },
        chargingBatteryFreeTimeRange() {
            return this.config.theFreeTime
        }
    },
    created() {
        this.dialogData = JSON.parse(JSON.stringify(this.dialogDataDefault))
    },
    methods: {
        async show(id, feeInfo) {
            await this.gainData()
            this.showDialog = true
            this.dialogData = {
                ...feeInfo,
                id
            }
        },
        // 获取数据
        async gainData() {
            await funConfig.GetClientConfig({ name: "clientConfig" }).then(res => {
                this.config = res
            })
        },
        // 提交
        _save() {
            this.$refs[this.formRefName].validate(async (valid) => {
                if (valid) {
                    window.$common.fullLoading()
                    funMerchant.SetFree(this.dialogData).then(res => {
                        window.$common.closeFullLoading()
                        this.successMsg("设置成功")
                        this._close()
                        this.$emit("confirm")
                    })
                }
            })
        },
        // 重置
        _reset() {
            this.confirm(`确定要重置当前套餐吗？`).then(() => {
                let commitData = JSON.parse(JSON.stringify(this.dialogDataDefault))
                commitData.id = this.dialogData.id
                window.$common.fullLoading()
                funMerchant.SetFree(commitData).then(res => {
                    window.$common.closeFullLoading()
                    this.successMsg("重置成功")
                    this._close()
                    this.$emit("confirm")
                })
            })
        },
        _close() {
            this.showDialog = false
        }
    }
}
</script>